window.onload = function () {
  const DEBUG = true;

  const selectTab = (event) => {
    const tabs = event.currentTarget;
    const parent = tabs.closest(".main__content");
    const target = event.target.getAttribute("data-target");

    const section = event.target.getAttribute("data-section");
    const tab = event.target.getAttribute("data-tab");
    sectionConfig[section].selectedTab = Number(tab);

    const active = parent.querySelectorAll(".active");
    if (event.target.classList.contains("main__tab")) {
      for (let i = 0; i < active.length; i++) {
        active[i].classList.remove("active");
      }
      event.target.classList.add("active");
      parent.querySelector(target).classList.add("active");
      event.preventDefault();
    }
  };

  document.getElementById("tab-1").addEventListener("click", selectTab);
  document.getElementById("tab-2").addEventListener("click", selectTab);
  document.getElementById("tab-3").addEventListener("click", selectTab);

  const swipers = new Swiper(".swiper", {
    pagination: {
      el: ".swiper-pagination",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  // Scrolling sliders
  const scrolllocker = new Scrolllocker();

  const blocks = document.getElementsByClassName("tab-slider-section");
  const sectionConfig = {
    "khanty-mansiysk-slider": { selectedTab: 1, lastTab: 3, sliders: 3 },
    "happy-new-year-slider": { selectedTab: 1, lastTab: 1, sliders: 1 },
    "surgut-slider": { selectedTab: 1, lastTab: 3, sliders: 3 },
    "kogalym-slider": { selectedTab: 1, lastTab: 3, sliders: 3 },
  };

  const blockObjects = [];

  const swipersCount = document.getElementsByClassName("swiper").length;
  let timeout = setTimeout(() => generateSections(), 500);
  const generateSections = () => {
    if (!swipers || swipers.length < swipersCount) {
      timeout = setTimeout(() => generateSections, 500);
    }
    let swiperOffset = 0;
    Array.from(blocks).forEach((element, index) => {
      if (blockObjects[index]) {
        blockObjects.push({ element, swipers: [] });
      } else {
        blockObjects[index] = { element, swipers: [] };
      }

      const slidersCount = sectionConfig[element.getAttribute("id")].sliders;
      for (let i = swiperOffset; i < swiperOffset + slidersCount; i++) {
        blockObjects[index].swipers.push(swipers[i]);
      }
      swiperOffset += slidersCount;
    });
  };

  let pausedScrollCheck = false;
  scrolllocker.onScroll((event, delta, enabled) => {
    if (pausedScrollCheck) return;
    let blockFound = false;
    blockObjects.some((block, index) => {
      const blockIndex = block.element.getAttribute("id");
      const blockOffset = document
        .getElementById(blockIndex)
        .getBoundingClientRect().top;

      if (DEBUG) console.log(`Block ${blockIndex} offset ${blockOffset}`);
      if (Math.round(blockOffset) <= 200 && Math.round(blockOffset) >= -200) {
        blockFound = true;
        const slider = block.swipers[sectionConfig[blockIndex].selectedTab - 1];

        if (slider.realIndex > 0 || sectionConfig[blockIndex].selectedTab > 1) {
          if (DEBUG) console.log("[SCROLL] To top locked");
          scrolllocker.scrollToAnchor(blockIndex);
          scrolllocker.disableScrollTop();

          if (delta < 0) {
            event.preventDefault();
            if (!enabled) {
              if (slider.realIndex > 0) {
                slider.slideTo(slider.realIndex - 1);
              } else {
                document
                  .getElementById(blockIndex)
                  .querySelector(
                    `[data-section='${blockIndex}'][data-tab='${
                      sectionConfig[blockIndex].selectedTab - 1
                    }']`
                  )
                  .click();
              }
            }
            pausedScrollCheck = true;
            setTimeout(() => {
              pausedScrollCheck = false;
            }, 1000);
            return true;
          }
        } else {
          if (DEBUG) console.log("[SCROLL] To top unlocked");
          scrolllocker.enableScrollTop();
        }

        if (
          slider.realIndex < slider.slides.length - 1 ||
          sectionConfig[blockIndex].selectedTab <
            sectionConfig[blockIndex].lastTab
        ) {
          if (DEBUG) console.log("[SCROLL] To bottom locked");

          scrolllocker.scrollToAnchor(blockIndex);
          scrolllocker.disableScrollBottom();

          if (delta > 0) {
            event.preventDefault();
            if (!enabled) {
              if (slider.realIndex < slider.slides.length - 1) {
                slider.slideTo(slider.realIndex + 1);
              } else {
                document
                  .getElementById(blockIndex)
                  .querySelector(
                    `[data-section='${blockIndex}'][data-tab='${
                      sectionConfig[blockIndex].selectedTab + 1
                    }']`
                  )
                  .click();
              }
            }
            pausedScrollCheck = true;
            setTimeout(() => {
              pausedScrollCheck = false;
            }, 1000);
            return true;
          }
        } else {
          if (DEBUG) console.log("[SCROLL] To bottom unlocked");
          scrolllocker.enableScrollBottom();
        }
      }
    });
    if (!blockFound) {
      scrolllocker.enableScrollBottom();
      scrolllocker.enableScrollTop();
    }
  });
};

const SCROLLLOCKER_NAME = "Scroll locker";
const SCROLL_KEYS = { 37: 1, 38: 1, 39: 1, 40: 1 };
const DEBUG = true;

class Scrolllocker {
  disabledTop = false;
  disabledBottom = false;
  wheelEvent = "wheel";
  supportsPassive = false;
  wheelOpt = false;
  onScrollCallback = () => {};

  constructor() {
    if (DEBUG) console.log(`[${SCROLLLOCKER_NAME}] Initializing hooks...`);

    this.onWheel = this.onWheel.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.wheelEvent =
      "keydown" in document.createElement("div") ? "wheel" : "mousewheel";

    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: () => {
            this.supportsPassive = true;
          },
        })
      );
    } catch (e) {}

    this.wheelOpt = this.supportsPassive ? { passive: false } : false;

    window.addEventListener("DOMMouseScroll", this.onWheel, false); // older FF
    window.addEventListener(this.wheelEvent, this.onWheel, this.wheelOpt); // modern desktop
    window.addEventListener("touchmove", this.onWheel, this.wheelOpt); // mobile
    window.addEventListener("keydown", this.onKeyDown, false);
    window.addEventListener("scroll", this.onScroll, false);
    if (DEBUG) console.log(`[${SCROLLLOCKER_NAME}] Initialized`);
  }

  onKeyDown(event) {
    if (SCROLL_KEYS[event.keyCode] && this.disabled) {
      event.preventDefault();
      console.log(`[${SCROLLLOCKER_NAME}] Try to cancel scroll event`);
      return false;
    }
  }

  onWheel(event) {
    const delta = Math.sign(event.deltaY);

    if (this.disabledTop && delta < 0) {
      this.onScrollHook(event, delta, false);
      event.preventDefault();
      if (DEBUG)
        console.log(`[${SCROLLLOCKER_NAME}] Try to cancel scroll event`);
      return false;
    }

    if (this.disabledBottom && delta > 0) {
      this.onScrollHook(event, delta, false);
      event.preventDefault();
      if (DEBUG)
        console.log(`[${SCROLLLOCKER_NAME}] Try to cancel scroll event`);
      return false;
    }
    this.onScrollHook(event, delta, true);
    let wheelVector;
    switch (delta) {
      case 1:
        wheelVector = "bottom";
        break;
      case -1:
        wheelVector = "top";
        break;
      default:
        wheelVector = "none";
    }
    if (DEBUG)
      console.log(`[${SCROLLLOCKER_NAME}] Wheel vector "${wheelVector}"`);
  }

  disableScrollTop() {
    this.disabledTop = true;
  }

  disableScrollBottom() {
    this.disabledBottom = true;
  }

  enableScrollTop() {
    this.disabledTop = false;
  }

  enableScrollBottom() {
    this.disabledBottom = false;
  }

  async scrollToAnchor(anchor) {
    if (!anchor) return;
    document.getElementById(anchor).scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  onScrollHook(event, delta, enabled) {
    this.onScrollCallback(event, delta, enabled);
  }

  /**
   * On scroll event
   *
   * @callback function () => {top: number; bottom: number; left: number; right: number}
   */
  onScroll(callback = () => {}) {
    this.onScrollCallback = callback;
  }
}
